<script setup>
import { computed, watch, ref, nextTick } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const error = computed(() => store.state.common.error)
</script>

<template>
    <div class="modal" :class="{ active: error.showError }">
        <div class="modal__window">
            <div class="modal__close" @click="error.showError = false">
                <span></span>
                <span></span>
            </div>
            <div class="modal__content" style="width: 450rem;">
                <div class="error__inner">
                    <div class="error__data">
                        <div class="icon">
                            <svg width="24rem" height="22rem" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.3765 0.0353584C10.2071 0.218987 9.15049 0.892284 8.49302 1.86692C8.2723 2.1918 0.61743 15.4883 0.424884 15.8791C-0.241982 17.2162 -0.115183 18.8736 0.744228 20.1449C1.32187 21.0018 2.20476 21.6233 3.22854 21.8917L3.62772 22H11.9401C20.8535 22 20.4872 22.0094 21.1776 21.7645C21.8116 21.5385 22.2483 21.256 22.7367 20.7711C23.1218 20.385 23.366 20.0366 23.6149 19.5139C23.9108 18.8877 24 18.4828 24 17.7153C24 16.9479 23.9108 16.5429 23.6149 15.9214C23.4411 15.5589 16.0774 2.73326 15.6313 2.01759C15.1852 1.2972 14.3492 0.572115 13.6683 0.317862C12.9544 0.0494843 12.0293 -0.0635166 11.3765 0.0353584ZM12.3862 1.9705C12.9498 2.05996 13.5133 2.38955 13.8326 2.82272C14.044 3.10522 21.6707 16.3358 21.8773 16.7737C22.2013 17.4564 22.1262 18.3227 21.6848 19.0054C21.3278 19.5657 20.6985 19.9565 20.0176 20.046C19.764 20.0789 17.0871 20.0931 11.7522 20.0836L3.86253 20.0695L3.54788 19.9612C2.25172 19.5233 1.58016 18.0496 2.12023 16.8302C2.21415 16.6136 9.74222 3.51485 10.0757 2.9781C10.4467 2.38484 11.2168 1.95638 11.9635 1.92342C12.0293 1.92342 12.2171 1.94225 12.3862 1.9705Z" fill="var(--primary-color)"/>
                                <path d="M11.7086 6.79216C11.3141 6.89575 11.0136 7.17825 10.8304 7.61613C10.7459 7.83272 10.7506 8.13405 10.8774 10.2293C10.9337 11.1851 11.0089 12.4187 11.0417 12.9649C11.1122 14.1608 11.1591 14.2879 11.5818 14.4998C11.873 14.6505 12.0749 14.6646 12.3285 14.5563C12.7324 14.3868 12.9484 14.0007 12.9484 13.4592C12.9437 13.3133 13.0141 12.0043 13.0987 10.5495C13.2677 7.63496 13.2677 7.65851 12.9907 7.25829C12.7277 6.87221 12.1782 6.67445 11.7086 6.79216Z" fill="var(--primary-color)"/>
                                <path d="M11.6832 15.5218C11.1149 15.7054 10.758 16.2186 10.8003 16.7836C10.8519 17.4616 11.3403 17.923 12.0072 17.9277C12.4862 17.9277 12.8807 17.6829 13.1014 17.2497C13.2235 17.0143 13.2376 16.4823 13.1296 16.228C13.0263 15.9926 12.7821 15.7242 12.5567 15.6112C12.35 15.5076 11.8757 15.4558 11.6832 15.5218Z" fill="var(--primary-color)"/>
                            </svg>
                        </div>
                        <div class="title" v-if="error.statusCode">Status code: {{ error.statusCode }}</div>
                        <div class="text" v-html="error.message"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.error {
    &__inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__data {
        border-radius: 8rem;
        width: 100%;
        padding: 30rem 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        .icon {
            background-size: 24rem 22rem;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(/img/danger.svg);
            width: 24rem;
            height: 22rem;
            margin-bottom: 10rem;
        }
        .title {
            font-size: 18rem;
            margin-bottom: 10rem;
            color: var(--primary-color);
        }
        .text {
            text-align: center;
            font-size: 16rem;
            text-align: center;
            font-family: "PTSans", sans-serif;
            color: var(--primary-color);
        }
    }
}
</style>